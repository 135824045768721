const routes = require('next-routes')();

const routesConfig = require('./conf/routes');

//HOME
routes.add({ name: 'home', pattern: '/', page: '/Homepage/index' });
routes.add({ name: 'fr_home', pattern: '/fr', page: '/Homepage/index' });
routes.add({ name: 'en_home', pattern: '/en', page: '/Homepage/index' });
routes.add({ name: 'es_home', pattern: '/es', page: '/Homepage/index' });
routes.add({ name: 'it_home', pattern: '/it', page: '/Homepage/index' });

Object.keys(routesConfig).forEach(function(categoryKey) {
  const routeConfig = routesConfig[categoryKey];

  Object.keys(routeConfig.langs).forEach(function(langKey) {
    const translatedRouteName = routeConfig.langs[langKey];

    routes.add({
      name: `${langKey}_${categoryKey}`,
      pattern: `/${langKey}/${translatedRouteName}`,
      page: routeConfig.page || `/${categoryKey}/index`
    });

    if (!routeConfig.single) {
      routes.add({
        name: `${langKey}_${categoryKey}_single`,
        pattern: `/${langKey}/${translatedRouteName}/:slug`,
        page: routeConfig.page || `/${categoryKey}/Single`
      });
    }
  });
});

module.exports = routes;
