import { useState, useEffect } from 'react';

const useExternalLink = link => {
  const [isExternalLink, setIsExternalLink] = useState(false);

  useEffect(
    () => {
      const hasHttp = link?.startsWith('http') || link?.startsWith('https');

      if (hasHttp) {
        const linkDomain = new URL(formattedUrl(link)).hostname;
        const currentDomain = new URL(formattedUrl(window.location.href))
          .hostname;

        if (linkDomain !== currentDomain) setIsExternalLink(true);
      }
    },
    [link]
  );

  return {
    isExternalLink
  };
};

const formattedUrl = url => {
  const hasWww = url.includes('www.');

  if (hasWww) {
    return url.replace('www.', '');
  }

  return url;
};

export { useExternalLink, formattedUrl };
