module.exports = {
  place: {
    langs: {
      fr: 'lieu',
      en: 'place',
      es: 'lugar',
      it: 'edificio'
    },
    single: true
  },
  shops: {
    langs: {
      fr: 'enseignes/:type?/:category?',
      en: 'brands/:type?/:category?',
      es: 'establecimientos/:type?/:category?',
      it: 'marchi/:type?/:category?'
    },
    single: false
  },
  restaurants: {
    langs: {
      fr: 'restaurants',
      en: 'restaurants'
    },
    single: false
  },
  hotel: {
    langs: {
      fr: 'hotel',
      en: 'hotel',
      es: 'hotel',
      it: 'hotel'
    },
    single: true
  },
  cig: {
    langs: {
      fr: 'cig',
      en: 'cig'
    },
    single: true
  },
  offers: {
    langs: {
      fr: 'offres',
      en: 'offers',
      es: 'ofertas',
      it: 'offerte'
    },
    single: false
  },
  nextdoor: {
    langs: {
      fr: 'a-cote',
      en: 'next-door'
    },
    single: true
  },
  application: {
    langs: {
      fr: 'application',
      en: 'application',
      es: 'aplicacion',
      it: 'applicazione'
    },
    single: true
  },
  enigma: {
    langs: {
      fr: 'enigme',
      en: 'enigma'
    },
    single: true
  },
  blog: {
    langs: {
      fr: 'blog/:category?',
      en: 'blog/:category?',
      es: 'blog/:category?',
      it: 'blog/:category?'
    },
    single: false
  },
  events: {
    langs: {
      fr: 'evenements/:category?',
      en: 'events/:category?',
      es: 'eventos/:category?',
      it: 'eventi/:category?'
    },
    single: false
  },
  infos: {
    langs: {
      fr: 'informations',
      en: 'practical-information',
      es: 'datos-practicos',
      it: 'informazioni-pratiche'
    },
    single: true
  },
  map: {
    langs: {
      fr: 'plan',
      en: 'map',
      es: 'mapa',
      it: 'mappa'
    },
    single: true
  },
  faq: {
    langs: {
      fr: 'faq',
      en: 'faq',
      es: 'preguntas-frecuentes',
      it: 'faq'
    },
    single: true
  },
  contact: {
    langs: {
      fr: 'contact',
      en: 'contact',
      es: 'contacto',
      it: 'contatti'
    },
    single: true
  },
  passeportFidelite: {
    langs: {
      fr: 'passeport-fidelite',
      en: 'passeport-fidelite',
      es: 'passeport-fidelite',
      it: 'passeport-fidelite'
    },
    single: true
  },
  partners: {
    langs: {
      fr: 'partenaires',
      en: 'partners',
      es: 'socios',
      it: 'partner'
    },
    single: true
  },
  press: {
    langs: {
      fr: 'espace-presse',
      en: 'press-area',
      es: 'espacio-de-prensa',
      it: 'stampa'
    },
    single: true
  },
  joinUs: {
    langs: {
      fr: 'nous-rejoindre',
      en: 'join-us'
    },
    single: true
  },
  offices: {
    langs: {
      fr: 'bureaux',
      en: 'offices',
      es: 'oficinas',
      it: 'uffici'
    },
    single: true
  },
  confidentiality: {
    langs: {
      fr: 'confidentialite',
      en: 'confidentiality',
      es: 'confidencialidad',
      it: 'informativa-privacy'
    },
    single: true
  },
  legals: {
    langs: {
      fr: 'mentions-legales',
      en: 'legal-information',
      es: 'aviso-legal',
      it: 'note-legali'
    },
    single: true
  },
  results: {
    langs: {
      fr: 'resultats/:query?',
      en: 'results/:query?',
      es: 'resultados/:query?',
      it: 'risultati/:query?'
    },
    single: true
  },
  generic: {
    langs: {
      fr: 'page/:slug?',
      en: 'page/:slug?',
      es: 'pagina/:slug?',
      it: 'pagina/:slug?'
    },
    single: false
  },
  newsletter: {
    langs: {
      fr: 'abonnement/:token',
      en: 'subscription/:token',
      es: 'suscripcion/:token',
      it: 'abbonamento/:token'
    }
  }
};
