import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Link from '../Link/Link';
import IconSVG from '../Icon/IconSVG';
import { useExternalLink } from '../../utils/links';

const StyledButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    transition: transform 0.3s ease-out;
  }

  &:hover {
    .button-text {
      color: ${({ theme }) => theme.color.brand.accent};
    }

    svg {
      transform: ${({ icon }) =>
        `rotate(${
          icon === 'arrowTopRight' ? 45 : icon === 'arrowBottomRight' ? -45 : 0
        }deg)`};
    }
  }
`;

const Text = styled.span`
  font-family: ${({ theme }) => theme.font.brand.secondary};
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.body2.fontSize};
  line-height: ${({ theme }) => theme.font.body2.lineHeight};
  color: ${({ textColor, theme }) => textColor || theme.color.brand.primary};
  margin-left: 10px;
  transition: color 0.3s ease-out;
`;

const Button = ({
  href,
  children,
  onClick,
  disabled,
  iconType,
  textColor,
  className
}) => {
  const { isExternalLink } = useExternalLink(href);

  const renderButton = () => {
    const icon =
      iconType || (isExternalLink ? 'arrowTopRight' : 'arrowBottomRight');

    return (
      <StyledButton
        onClick={onClick}
        disabled={disabled}
        icon={icon}
        className={className}
      >
        <IconSVG name={icon} />
        <Text textColor={textColor} className="button-text">
          {children}
        </Text>
      </StyledButton>
    );
  };

  return (
    <>{href ? <Link href={href}>{renderButton()}</Link> : renderButton()}</>
  );
};

Button.propTypes = {
  href: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  iconType: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string
};

export default Button;
