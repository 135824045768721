import PropTypes from 'prop-types';
import React from 'react';
import { useExternalLink } from '../../utils/links';
import { Link as NextLink } from '../../../routes';
import IconSVG from '../Icon/IconSVG';
import { translate } from 'react-i18next';
import styled from 'styled-components';

const StyledLink = styled.a`
  text-decoration: none;
`;

const Link = ({ children, href, i18n, withIcon }) => {
  const { isExternalLink } = useExternalLink(href);

  const reformattedHref = href.startsWith('mailto:')
    ? href
    : `/${i18n.language}/${href}`;

  return (
    <>
      {isExternalLink ? (
        <StyledLink href={href} target="_blank">
          {withIcon && <IconSVG name="arrowExternalLink" mr={3} />}
          {children}
        </StyledLink>
      ) : (
        <NextLink href={reformattedHref} passHref>
          <StyledLink>{children}</StyledLink>
        </NextLink>
      )}
    </>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  i18n: PropTypes.object,
  withIcon: PropTypes.bool
};

export default translate()(Link);
